/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'file-earmark-easel': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M8.5 6a.5.5 0 10-1 0h-2A1.5 1.5 0 004 7.5v2A1.5 1.5 0 005.5 11h.473l-.447 1.342a.5.5 0 10.948.316L7.027 11H7.5v1a.5.5 0 001 0v-1h.473l.553 1.658a.5.5 0 10.948-.316L10.027 11h.473A1.5 1.5 0 0012 9.5v-2A1.5 1.5 0 0010.5 6zM5 7.5a.5.5 0 01.5-.5h5a.5.5 0 01.5.5v2a.5.5 0 01-.5.5h-5a.5.5 0 01-.5-.5z"/><path pid="1" d="M14 14V4.5L9.5 0H4a2 2 0 00-2 2v12a2 2 0 002 2h8a2 2 0 002-2M9.5 3A1.5 1.5 0 0011 4.5h2V14a1 1 0 01-1 1H4a1 1 0 01-1-1V2a1 1 0 011-1h5.5z"/>',
    },
});
